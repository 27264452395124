import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "Krishna Farms",
  address: "Vrindavan, Uttra Pradesh",
  client: "",
  area: "12.0 Acres",
  project: "Krishna Farms, Vrindavan",
  category: "Landscape | Architecture",
  status: "Completed",
  backlink: "/projects/landscape/",
};

export default class Projectkrishnafarms extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Signature Landscape Projects | Krishna Farms"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="View a showcase of pictures of the lawn layout & landscape design for a residential property spread over 12 acres in Vrindavan, Uttar Pradesh."
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
